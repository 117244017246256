import './style/style.css'
import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Gallery from './pages/gallery';

function App() {
  return (
    <>
    <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/gallery" element={<Gallery/>} />
        </Routes>
    </Router>
    </>
    
  );
}

export default App;
