import Sidebar from "../components/sidebar"
import Logo from "../images/logo.png"
import Heading from "../images/LitaHusin.png"
import { Link } from "react-router-dom"

export default function Home() {
    return(
        <>
        <div className="home">
            <Sidebar/>
            <Link to='/'><img className="logo" src={Logo} /></Link>
            <div className="home-content">
                <img src={Heading} />
                <h1>Digital Gallery</h1>
                <Link to='/gallery'><button>Visit Gallery</button></Link>
            </div>
        </div>
        </>
        
    )
}