import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import Facebook from '../images/fb.png'
import Instagram from '../images/ig.png'
import Heading from '../images/heading.png'
import CloseIcon from '../images/close-black.png'
import Contact from "./contact";

export default function Sidebar({ zIndex }) {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [showAboutPopup, setShowAboutPopup] = useState(false);
  const [showContactPopup, setShowContactPopup] = useState(false);

  useEffect(() => {
    // Mengatur isActive menjadi true saat di halaman pertama
    setIsActive(location.pathname === '/');

    // Add a resize event listener to check window width
    const handleResize = () => {
      if (location.pathname === '/') {
        setIsActive(window.innerWidth > 768);
      }
    };

    // Call handleResize initially to set the initial value of isActive
    handleResize();

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location]);

  const handleClick = () => {
    // Mengubah status aktivitas div saat tombol diklik
    setIsActive(!isActive);
  };

  const handleAboutClick = () => {
    // Mengubah status visibilitas popup About saat diklik
    setShowAboutPopup(!showAboutPopup);
    setShowContactPopup(false); 
  };

  const handleContactClick = () => {
    // Mengubah status visibilitas popup Contact saat diklik
    setShowContactPopup(!showContactPopup);
    setShowAboutPopup(false); // Menutup popup About
  };

  const closePopup = () => {
    // Menutup popup About
    setShowAboutPopup(false);
    setShowContactPopup(false);
  };

  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const token = 'Aa5PbtwL9kXHL5n0/pwQMvVo1BhqPj7G0tROQO6Pa4g='
  const url = `https://admin.litahusin.com/api/bio?token=${token}`
  useEffect(() => {
    setLoading(true) 
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data.value_response)
        
        setLoading(false)
      })
  }, [])

  if (isLoading) return <p className="loadStyle">Loading...</p>
  if (!data) return <p className="loadStyle">No profile data</p>
  return (
    <>
    <div className='sidebar-layout' style={{ zIndex }}>
      <div className={`sidebar ${isActive ? 'sidebar-active' : ''}`}>
        <div className={`menu-link ${isActive ? 'menu-link-active' : ''}`}>
          <button onClick={handleClick}>Menu</button>
        </div>

        <ul>
          <li onClick={handleAboutClick} className={showAboutPopup ? 'active' : ''}>About</li>
          <li onClick={handleContactClick} className={showContactPopup ? 'active' : ''}>Contact</li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
        </ul>
      </div>
    </div>
      

      <div className={`about-popup ${showAboutPopup ? 'active' : ''}`}>
        <div className="about-content">
          <button className="close-about" onClick={closePopup}><img src={CloseIcon} alt="Close" /></button>
          <div className="pop-upBox-about">
            <div className="image-about">
              <div className="image-about-box">
                <img src={data.profile} alt="Profile" />
              </div>
              <div className="social-about">
                <img src={Facebook} alt="Facebook" />
                <img src={Instagram} alt="Instagram" />
              </div>
            </div>
            <div className="box-content-5 box-content-about">
              <div className="heading-about-content">
                <img src={Heading} alt="Heading" />
              </div>
              <p>{data.bio}</p>
            </div>
            <div className="logo-box-about">
              <h1>Indonesia Artist</h1>
            </div>
          </div>
        </div>
      </div>

      <div className={`contact-popup ${showContactPopup ? 'active' : ''}`}>
          <div className="contact-content">
            <button className="close-about" onClick={closePopup}><img src={CloseIcon} alt="Close" /></button>
            <div className="pop-upBox-contact">
              <h1>CONTACT THE ARTIST</h1>
              <Contact/>
            </div>
          </div>
        </div>
    </>
  );
}
