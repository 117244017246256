import Sidebar from "../components/sidebar"
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from "react-router-dom";
import Logo from "../images/logo.png"
import { Navigation } from 'swiper/modules';
import axios from "axios";
import HeadingGallery from '../images/heading.png'
import Close from '../images/close-black.png'
import ClassNames from "classnames";
import nextSlide from "../images/next.png"
import prevSlide from "../images/prev.png"


export default function Gallery() {
  const [showSidebar, setShowSidebar] = useState(true);
  const [data, setData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0); 
  const [showNavigation, setShowNavigation] = useState(true);
  const [popupOpenIndex, setPopupOpenIndex] = useState(-1);
  const [activeSlide, setActiveSlide] = useState(0);
  
  const token = 'Aa5PbtwL9kXHL5n0/pwQMvVo1BhqPj7G0tROQO6Pa4g=';
  const url = `https://admin.litahusin.com/api/AllNews?token=${token}`;

  const getData = async () => {
    const { data } = await axios.get(url);
    const news = data.value_response.slice();
    setData(news);
  };

  useEffect(() => {
    getData();
  }, []);

  const openPopupGallery = () => {
    setShowSidebar(false);
    setShowPopup(true);
    setShowNavigation(false);
  };

  const closePopupGallery = () => {
    setShowSidebar(true);
    setShowPopup(false);
    setShowNavigation(true);
  };

  const prev = () => {
    setPopupOpenIndex(-1);
    setActiveSlide((curr) => (curr === 0 ? 0 : curr - 1)); // Jika curr === 0, tetap pada slide pertama
  };

  const next = () => {
      setPopupOpenIndex(-1);
      setActiveSlide((curr) => (curr === data.length - 1 ? curr : curr + 1)); // Jika curr === data.length - 1, tetap pada slide terakhir
  };


  return (
    <>
      <div className="gallery">
        <Sidebar zIndex={showPopup ? 1 : 'auto'} />
        <Link to='/' ><img className={ClassNames('logo', { 'active': showPopup })} src={Logo} /></Link>
        <Swiper navigation={showNavigation} modules={[Navigation]} onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}>
          {data &&
            data.map((news, i) => {
              return (
                <SwiperSlide key={i}>
                  <button onClick={openPopupGallery} className="info-button">
                    Info
                  </button>
                  <div className='glow-effect'>
                    <div className='glow'></div>
                    <div className='glow glow2'></div>
                    <div className='glow glow3'></div>
                    <div className='glow glow4'></div>
                  </div>
                  <div className="box-gallery">
                    <img src={news.media} alt='Lita Husin Digital Gallery' className="image-gallery" />
                    <h1>{news.title}</h1>
                    <img className="heading-gallery" src={HeadingGallery} />
                  </div>
                  <div className={ClassNames('popup', { 'active': showPopup })} >
                      <div className="popup-content">
                        <button className="close-button" onClick={closePopupGallery}>
                          <img src={Close} alt="Close" />
                        </button>
                        <div className='popup-art'>
                          <div className="image-box">
                            <div className='image-box-art'>
                              <img src={news.media} alt='Lita Husin' />
                            </div>
                          </div>
                          <div className="box-content">
                            <h1>{news.title}</h1>
                            <p>{news.news_post}</p>
                          </div>
                          <div className="logo-box">
                            <div className='logo-box-image'>
                              <img src={HeadingGallery} />
                            </div>
                            <p>2019</p>
                          </div>
                        </div>
                      </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
        <div className="slide-mobile">
        <Link to='/' ><img className='logo-mobile' src={Logo} /></Link>
          <div className="slide-box">
              {data &&
                  data.map((news, indeks) => (
                    <>
                      <div key={indeks} className={`slide-box-img ${activeSlide === indeks ? 'active' : ''}`}>
                          <button className="open-popup-mobile" onClick={() => setPopupOpenIndex(indeks)}>Info</button>
                          <img src={news.media} alt={`Slide ${indeks}`} className="image-gallery"/>
                          <h3>{news.title}</h3>
                          <img className="heading-gallery" src={HeadingGallery} />
                          {popupOpenIndex === indeks && (
                            <div className="popup-mobile">
                                <button className="close-popup-mobile" onClick={() => setPopupOpenIndex(-1)}>
                                  <img src={Close} alt="Close" />
                                </button>
                                {/* <img src={news.media} className="image-popup-mobile" /> */} 
                                  <div className='image-box-art'>
                                    <img src={news.media} alt='Lita Husin' />
                                  </div>
                                <h3>{news.title}</h3>
                                <p>{news.news_post}</p>
                                <div className="logo-box">
                                  <div className='logo-box-image'>
                                    <img src={HeadingGallery} />
                                  </div>
                                  <p>2019</p>
                                </div>
                            </div>
                          )}
                      </div>
                      
                    </>
               ))}
          </div>
          <div className="navigation-slide">  
            <button onClick={prev}>
                <img src={prevSlide}/>
            </button>
            <button onClick={next}>
                <img src={nextSlide}/>
            </button>
          </div>
        </div>
        
      </div>
      <style jsx>{`
      .logo-mobile{
        display:none;
      }
      .slide-mobile{
        display:none;
      }
        @media(max-width:768px){
          
          .close-popup-mobile{
            position:absolute;
            right:4%;
            top:-9%;
            background:transparent;
            outline:none;
            border:none;
            width:max-content;
            height:max-content;
            padding:0;
          }
          .close-popup-mobile img{
            width:85%;
          }
          .logo-mobile{
            display:block;
            max-width:70%;
            position:absolute;
            top:0;
            left:50%;
            transform:translateX(-50%);
          }
          .slide-mobile{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100vh;
            display:block;
            padding-top:45vw;
            overflow:hidden;
          }
          .navigation-slide{
            position: absolute;
            top: 93%;
            display: flex;
            justify-content: space-between;
            transform: translateY(-50%);
            width: 100%;
            padding:0 6vw;
            margin:auto;
            z-index:0;
          }
          .navigation-slide button{
            background:transparent;
            border:none;
            outline:none;
            color:black;
          }
          .navigation-slide img{
            width:70%;
          }
          .slide-box {
              display: flex;
              transition: transform 0.3s ease-in-out;
              transform: translateX(-${activeSlide * 100}%);
              z-index:3;
              height:100vh;
              
          }
          .slide-box-img {
              flex: 0 0 100%;
              text-align: center;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position:relative;
              opacity: 0.5; /* Mengurangi opasitas untuk slide non-aktif */
          }
          .slide-box-img.active {
              opacity: 1; /* Memberikan opasitas penuh pada slide aktif */
          }
          .slide-box-img .image-gallery {
              max-width: 85.5vw;
              max-height: 83vw;
              display: block;
              box-shadow: 3px 3px 7px 0px rgba(0,0,0,0.43) !important;
    -webkit-box-shadow: 3px 3px 7px 0px rgba(0,0,0,0.43) !important;
    -moz-box-shadow: 3px 3px 7px 0px rgba(0,0,0,0.43) !important; 
              margin: auto;
              margin-bottom:4vw;
              object-position: center;
              object-fit: contain;
              border: 1.8vw solid white !important;
          }
          .slide-box-img .image-gallery{
              transform:scale(1);
          }
          .open-popup-mobile{
            position:absolute;
            z-index:10;
            width:16vw;
            font-weight:600;
            font-size:4vw;
            text-transform:uppercase;
            height:16vw;
            border-radius:50vw;
            background:white;
            border:.1vw solid rgba(0, 0, 0, 0.19);
            outline:none;
            right:3%;
            top:14%;
            color:black;
          }
          .slide-box-img h3{
            font-size:7.5vw;
            font-weight:600;
            margin-bottom:1.8vw;
            color:black;
          }
          .popup-mobile {
              position: fixed;
              top:-10%;
              bottom: 0;
              width: 100%;
              height:max-content;
              background: white;
              padding:3vw;
              z-index:20;
          }   
          .image-popup-mobile{
            max-width:100% !important;
            max-height:55%;
          }    
          .popup-mobile h3{
            font-size:9vw;
            text-transform:uppercase;
            margin-top:2.5vw;
            color:black;
            margin-bottom:0 !important;
          }  
          .popup-mobile p{
            font-size:5vw;
            font-weight:500;
            margin-bottom:3vw;
            color:black;
          }
        }
    `}</style>
    </>
  );
}

