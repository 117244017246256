import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true); // Mengatur status loading menjadi true

    emailjs
      .sendForm('service_ryqrnlo', 'template_jf2hc2m', form.current, 'uVmYdsA1CMBgV57CP')
      .then((result) => {
        console.log(result.text);
        setIsSent(true); // Mengatur state menjadi true saat pengiriman berhasil
        setIsLoading(false); // Mengatur status loading menjadi false setelah pengiriman selesai
      })
      .catch((error) => {
        console.log(error.text);
        setIsLoading(false); // Mengatur status loading menjadi false jika terjadi kesalahan pengiriman
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail} className='form-input'>
      <input
        type="text"
        placeholder='Name'
        name="user_name"
        required
      />
      <input
        type="email"
        placeholder='Email'
        name="user_email"
        required
      />
      <textarea
        placeholder='Message'
        name="message"
        required
      ></textarea>
      <button type="submit" disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Send'}
      </button>
      {isSent && <p>Pesan telah terkirim</p>}
    </form>
  );
};

export default ContactForm;
